import React, {useEffect} from 'react'

import {Container, Row, Col} from "react-bootstrap"
import {Link} from "react-router-dom"

import {GiOpenBook} from "react-icons/gi"
import {HiLightBulb} from "react-icons/hi"
import {FaExternalLinkAlt} from "react-icons/fa"

import PdfData from "../../data/PdfData"
import WikiData from '../../data/WikiData'
import Chart from '../../elements/Chart/Chart'
import YoutubeVideo from '../../elements/YoutubeVideo/YoutubeVideo'

import "./Home.css"
import "../../global.css"

import { ScrollToTopOnMount } from '../../functions/ScrollToTop'

import CalimathLogo from "../../img/drawing-dark.png"
import { NewFeature } from '../../elements/ForegroundStuff/ForegroundStuff'


function Home({customDate, date}) {
  useEffect(() => {
    document.title = "Calimath - Home"
  }, [])
  return (
      <Container fluid>
        <ScrollToTopOnMount />
        <Row>
          <Col xl="12">
            <div className="content-box welcome">
              <h1>
                Welcome to calimath.org!
              </h1>
              <img src={CalimathLogo} alt="Calimath logo" className="title-logo"/>
              <Container fluid>
                <Row>
                  <Col lg="3" md="6" className="text-col">
                  <div className="content-box2">
                    <h2>
                      We host a collection of{" "}
                      <Link to="problems-solutions" className="primary-text">
                        problems and solutions
                      </Link>{" "}
                      from mathematical olympiads on the national or international level.
                    </h2>
                  </div>
                  </Col>
                  <Col lg="3" md="6" className="text-col">
                  <div className="content-box2">
                    <h2>
                      In our solutions, we cite definitions and results from the {" "}
                      <Link to="wiki" className="primary-text">
                        Calimath Wiki
                      </Link>
                      .
                    </h2>
                  </div>
                  </Col>
                  <Col lg="3" md="6" className="text-col">
                  <div className="content-box2 relative">
                    <h2>
                      Important information for one topic is collected in{" "}
                      <Link to="skillpages" className="primary-text">
                        Skillpages
                      </Link>. A Skillpage contains the relevant information on a certain topic and links to all related problems on our site.
                    </h2>
                  </div>
                  </Col>
                  <Col lg="3" md="6" className="text-col">
                  <div className="content-box2">
                    <h2>
                      We upload all solutions on our {" "}
                      <a href="https://www.youtube.com/channel/UC-DagxlU75SXG0FntGxKvTA" className="primary-text">
                        Youtube Channel <FaExternalLinkAlt size = {10}/>
                      </a>
                      . We use the videos to explain how to come up with the solutions.
                    </h2>
                  </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
        {/*
        <Row>
          <Col xs="12">
            <div className="content-box">
              <YoutubeVideo />
            </div>
          </Col>
        </Row>
        */}
        <Row>
          <Col xs="12">
            <div className="content-box">
              <Row>
                <Col md="6" className="chart-col">
                  <Chart customDate={customDate} date={date} Icon={HiLightBulb} title="Number of problems and solutions" data={PdfData} target={"/problems-solutions"} pdfs={true} />
                </Col>
                <Col md="6" className="chart-col">
                  <Chart customDate={customDate} date={date} Icon={GiOpenBook} title="Number of Wiki entries" data={WikiData} target={"/wiki"} pdfs={false} />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
  )
}

export default Home