import SkillsData from "./SkillsData"
import { InfoTagData } from './TagData'
import WikiData from "./WikiData"
import PdfData from "./PdfData"

import "./data.css"
import { LectureHeadData } from "./LectureHeadData"

export const SkillPageMenuData = {
    NT_FUNCTIONS: {
        id: 0,
        title: "Number theoretic functions",
        content: [
            {
                id: 0,
                title: "List of number theoretic functions",
                pdfs: [],
                content: [WikiData.EULERS_TOTIENT_FUNCTION, WikiData.DIVISOR_SUM_FUNCTION, WikiData.P_ADIC_VALUATION, WikiData.PRIME_OMEGA_FUNCTION, WikiData.LEGENDRE_SYMBOL, WikiData.MULTIPLICATIVE_ORDER, WikiData.NUMBER_OF_PRIMES, WikiData.GCD_AND_LCM, WikiData.MOEBIUS_FUNCTION]
            },
            {
                id: 1,
                title: "Theorems",
                pdfs: [],
                content: [WikiData.LTE_LEMMA, WikiData.LAW_OF_QUADRATIC_RECIPROCITY, WikiData.EULERS_CRITERION]
            },
            {
                id: 2,
                title: "Fun facts",
                pdfs: [],
                content: [SkillsData.VALUES_OF_SELECTED_SERIES],
            }
        ],
        infoTags: [InfoTagData.NT_FUNCTION],
        public: true,
    },
    GRAPH_THEORY: {
        id: 1,
        title: "Graph theory",
        content: [
            {
                id: 0,
                title: "Important definitions",
                pdfs: [],
                content: [WikiData.UNDIRECTED_GRAPH, WikiData.INDUCED_SUBGRAPH, WikiData.TREE_GRAPH]
            },
            {
                id: 1,
                title: "Other words you should understand",
                pdfs: [],
                content: [WikiData.SUBGRAPH, WikiData.COMPONENT_GRAPH, WikiData.CONNECTED_GRAPH, WikiData.BIPARTITE_GRAPH, WikiData.BRIDGE_GRAPH, WikiData.CYCLE_FREE_GRAPH, WikiData.PLANAR_GRAPH, WikiData.MATCHING]
            },
            {
                id: 2,
                title: "Facts",
                pdfs: [],
                content: [WikiData.EULER_CHARACTERISTIC, WikiData.HANDSHAKING_LEMMA, WikiData.HALLS_MARIAGE_THEOREM]
            }
        ],
        infoTags: [InfoTagData.GRAPH_THEORY],
        public: true
    },
    FUNCTIONAL_EQUATIONS: {
        id: 2,
        title: "Functional equations",
        content: [
            {
                id: 0,
                title: "General strategies that are always worth a try",
                pdfs: [],
                content: [SkillsData.GUESSING_ALL_SOLUTIONS, SkillsData.CHECKING_INJECTIVE_SURJECTIVE, SkillsData.PLUGGING_IN_SMALL_VALUES_FE, SkillsData.MAKING_TERMS_CANCEL, SkillsData.USING_SYMMETRY, SkillsData.MONOTONY_FE, SkillsData.INVESTIGATING_ROOTS, SkillsData.INDUCTION_FE, SkillsData.ASSUMING_DIFFERENT_VALUE],
            },
            {
                id: 1,
                title: "Theorems",
                pdfs: [],
                content: [WikiData.CAUCHY_FES],
            },
            {
                id: 2,
                title: "Warnings",
                pdfs: [],
                content: [SkillsData.CHECK_THE_SOLUTION_FE, SkillsData.BEWARE_OF_THE_DOMAIN_FE]
            }
        ],
        infoTags: [InfoTagData.FUNCTIONAL_EQUATION],
        public: true
    },
    COMPLEX_NUMBERS: {
        id: 3,
        title: "Complex numbers",
        content: [
            {
                id: 0,
                title: "Definitions",
                pdfs: [PdfData.INTRODUCING_THE_IMAGINARY_NUMBER_AND_OTHER_ROOTS_OF_UNITY],
                content: [WikiData.COMPLEX_NUMBERS, WikiData.ABSOLUTE_VALUE_COMPLEX, WikiData.COMPLEX_ARGUMENT, WikiData.COMPLEX_PLANE, WikiData.COMPLEX_POLAR_COORDINATES],
            },
            {
                id: 3,
                title: "Polynomials",
                pdfs: [],
                content: [WikiData.FUNDAMENTAL_THEOREM_OF_ALGEBRA, WikiData.ROOTS_OF_UNITY],
            },
            {
                id: 1,
                title: "Some applications",
                pdfs: [],
                content: [WikiData.ROOTS_OF_UNITY_FILTER, WikiData.REAL_POLYNOMIAL_FACTORIZATION, WikiData.TRIGONOMETRIC_FORMULAS, SkillsData.FIELD_EXTENSIONS],
            },
            {
                id: 2,
                title: "Complex bash",
                pdfs: [],
                content: [SkillsData.GENERAL_COMPLEX_BASH, SkillsData.COLLINEARITY_COMPLEX, SkillsData.REFLECTIONS_AND_PROJECTIONS, SkillsData.TRIANGLE_CENTERS, SkillsData.MORE_PROPERTIES],
            },
        ],
        infoTags: [InfoTagData.COMPLEX_NUMBERS],
        public: true
    },
    COMBINATORIC_IDENTITIES: {
        id: 4,
        title: "Combinatoric identities",
        content: [
            {
                id: 0,
                title: "Binomial theorem, Hockeystick, sum binom(n)(k)^2",
                pdfs: [],
                content: [],
            },
            {
                id: 1,
                title: "Generating functions, derivative, fibonacci, catalan",
                pdfs: [],
                content: [],
            }
        ],
        infoTags: [InfoTagData.COMBINATORIC_IDENTITY],
        public: false
    },
    PROBABILITY_THEORY: {
        id: 5,
        title: "Probability theory",
        content: [
            {
                id: 0,
                title: "Introduction to probability theory",
                pdfs: [],
                content: [WikiData.PROBABILITY_SPACE, WikiData.RANDOM_VARIABLE, WikiData.EXPECTATION, WikiData.VARIANCE_AND_COVARIANCE, WikiData.EXPECTATION_BOUNDS_MAXIMUM, WikiData.CONDITIONAL_PROBABILITY, WikiData.INDEPENDENCE],
            },
            {
                id: 1,
                title: "Important distributions of random variables",
                pdfs: [],
                content: [WikiData.BERNOULLI_AND_BINOMIAL_DISTRIBUTION, WikiData.GEOMETRIC_DISTRIBUTION],
            },
        ],
        lectureHeads: [LectureHeadData.PROBABILITY_SPACE, LectureHeadData.RANDOM_VARIABLE, LectureHeadData.EXPECTATION],
        infoTags: [InfoTagData.PROBABILITIES],
        public: false
    },
}